<template>
    <div class="page">
        <div class="content">
            <div class="top">
                <DatePicker type="daterange" @on-change="changeDate" placement="bottom-start" placeholder=" 开始时间 至 结束时间 " style="width:15vw" separator=" 至 "></DatePicker>
                <Button type="primary" style="background: #296FE1;margin-left: 30px;" @click="search">搜索</Button>
            </div>
            <div style="margin-bottom: 10px;font-family: Microsoft YaHei;font-weight: 400;font-size: 18px;color: #333333;">分红金额: 
                <span style="color: #296FE1;">￥{{money}}</span>
            </div>
            <div style="min-height: 65vh;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="title">
                        <div v-if="row.jobs">{{row.jobs.title}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="signup">
                        <div v-if="row.signup">{{row.signup.user.name}}</div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
    </div>
</template>

<script>
import { balanceList } from "@/api/index";
    export default {
        data() {
            return {
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    start_date: '',
                    end_date: ''
                },
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'130px'},
                    {title: '标题',slot: 'title',align:'center'},
                    {title: '客户名称',slot: 'signup',align:'center'},
                    {title: '分红金额',key: 'money',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'}
                ],
                dataTable: [],
                money: ''
            }
        },
        created(){},
        mounted(){
            this.balanceList()
        },
        methods: {
            search(){
                this.balanceList()
            },
            balanceList(){
                balanceList(this.storePrams).then(res => {
                    this.money = res.data.money
                    this.dataTable = res.data.list.data
                    this.total = res.data.list.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            changeDate(e){
                if(e[0]){
                    this.storePrams.start_date = e[0]
                    this.storePrams.end_date = e[1]
                }else {
                    this.storePrams.start_date = ''
                    this.storePrams.end_date = ''
                }
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.balanceList()
            }
        }
    }
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #F5F9FA;
}
// table中所有的边框 
::v-deep .ivu-table td{
    border-left: none;
    border-right: none;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #666666 !important;
}
</style>